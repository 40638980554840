/* src/App.css */
@font-face {
  font-family: 'Anic';
  src: url('./panicLight.woff2') format('opentype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Anic';
  src: url('./panicLight.woff2') format('opentype');
  font-weight: 300;
  font-display: swap;
}

body {
  font-family:'Anic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* font-family: 'FallbackFont', sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Anic',
    monospace;
}
/* Example usage */
.bold-text {
  font-weight: bold;
}

.light-text {
  font-weight: 300;
}