@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=Press+Start+2P&display=swap');


.conta1 {
    background-image: url('../Assets/chpanic.GIF');
    background-size: cover;
    /* background-position: center center; */
    width: 100%;
    height: 100vh;
    /* background-color: #ff501e; */
    /* overflow: hidden; */

    /* font-family: 'Anic'; */
}
.introP {
  position: absolute;
  top: 201px;
  width: 70%;
  font-weight: bold;
  left: 219px;
  color: #FFD21E;
  font-family: "Anic", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: 700; */
  font-style: normal;
  font-size: 19px;
  text-shadow: 1px 1px #EBBE0A;
}
.CHcontainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    top: 400px;
    width: 60%;
    left: 20%;
    /* background-color: orange; Adjust this to match the background color in your image */
  }
  
  .box {
    padding: 20px;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
    top: 104px;
    position: relative;
    left: -35px;
  }
  .boxb {
    padding: 20px;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
    position: relative;
    top: 104px;
    left: 142px;
  }
  
  .image {
    width: 65px;
    height: 65px;
    margin-bottom: 10px;
  }
  
  .text {
    color: yellow; /* Adjust this to match the text color in your image */
    /* font-size: 18px; */
    font-weight: bold;
    position: relative;
    top: -13px;
    text-transform: uppercase;
    font-weight: bold;
    /* left: 219px; */
    color: #FFD21E;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: 700; */
    font-style: normal;
    font-size: 19px;
    text-shadow: 1px 1px #EBBE0A;
  }
  
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .conta1{
        background-image: url('../Assets/Chapter1bg.gif');
        background-size: cover;
        background-size: 133%;
        background-position-x: 3px;
        background-repeat: no-repeat;
        /* background-position: center center; */
        width: 100vw;
        height: 100vh
    }
    .introP{
      left: 35px;
      top: 164px;
      width: 77%;
      font-size: 13px;
    }
    .CHcontainer {
        top: 280px;
        padding-bottom: 330px;
        width: 90%;
        left: 2%;
        
      }

      .box {
        width: 48px;
        height: 48px;
        position: relative;
        left: 27px;
        top: 248px;
    }
      
        .boxb {
          width: 48px;
        height: 48px;
        position: relative;
        top: 248px;
        left: -54px;
      }
      .text {
        top: -16px;
        left: -17px;
      }
}