

.Gcont {
  position: relative;
  top: -270px;
  

   /* color: #FFD21E; */
   font-family: "Anic", sans-serif;
   /* font-optical-sizing: auto;
   
   font-style: normal;
   font-size: 19px;
   text-shadow: 1px 1px #EBBE0A; */
}
.show  {
  margin-bottom: 222px;
}
.btn2 img {
  /* background-image: url("../../Greetings/Assets/byhitch.png"); */
  /* background-color: #ce9c11; */
  width: 110px; 
  height: 130px;
  position: relative;
  /* overflow: hidden; */
  top: -59px;
}


/* .Gcont .homenavs .homebtn {
  /* /* font-size: 120px;
  position: relative;
    left: 10%;
    width: 30px;
    top: 90px; */
    /* font-family: 'Satisfy', cursive; */
  /* font-style: italic;
  text-decoration: none;
  color: #141414;
  font-weight: bold; */
  /* color: #ce9c11; */
  /* cursor: pointer; */
  /* border: 1px solid currentColor; 
} */


.Gcont .homenavs {
   margin-bottom: 70px;
}
.Gcont .homenavs .btn {
    display: flex;
    justify-content: space-around;

}
.Gcont .homenavs .btn .panicportal {
    text-decoration: none;
  /* color: yellow; */
  font-weight: bold;
    border: 1px solid transparent;
    transition: border 0.3s;
    padding: 15px 55px;
    /* height: 14px; */
    background-color: #141414;
    border-radius: 80%;
    /* box-shadow: h-offset v-offset blur spread #f7be22; */
    /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
    font-weight: bold;
    white-space: nowrap;
    position: relative;
    left: 19%;
    height: 25px;
    /* flex: 1; */
}



/* .Gcont .homenavs .homebtn:hover {
  color: #ce9c11;
} */
.discN {
  width: 310px;
  height: 295px;
  position: relative;
  top: -20px;
  left: 20%;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../Assets/Btns/Bdisc.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.discN:hover {
  background-image: url("../Assets/Btns/disc.png");
}
.Gcont .homenavs .btn2 {
    text-decoration: none;
    color: black;
    font-weight: bold;
      border: 1px solid transparent;
      transition: border 0.3s;
      /* padding: 15px 55px; */
      /* height: 14px; */
      /* background-color: #ce9c11; */
      border-radius: 80%;
      /* box-shadow: h-offset v-offset blur spread #f7be22; */
      /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
      font-weight: bold;
      white-space: nowrap;
      position: relative;
      left: 4%;
}
.Gcont .homenavs .Gintro {
  /* position: absolute; */
  font-weight: bold;
  width: 80%;
  height: 100vh;
  position: relative;
  top: -150px;
  /* background-image: url("./panic_bg.png"); */
  background-size: cover;
  background-position: center;
  /* background-repeat: no-repeat; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
/* .bgImg {
  position: absolute;
} */
.p {
  font-size: 27px;
  width: 85%;
  margin-left: 90px;
  font-weight: bold;
  word-spacing: 10px;
}


.homebtn{
  width: 100px;
  position: relative;
  height: 125px;
  top: 3rem;
  left: 10rem;
    background-image: url(/src/components/Home/panic\ black.png);
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
}

.homebtn:hover {
  background-image: url(
"/src/components/Home/panic\ yellow.png");
}

.homebtna{
  width: 57px;
  position: relative;
  height: 73px;
  top: 3rem;
  left: 10rem;
    background-image: url(/src/components/Home/panic\ yellow.png);
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
}

.homebtna:hover {
  background-image: url(
"/src/components/Home/panic\ black.png");
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .Gcont {
    margin-top: 50px;
    height: 1700px;
  }
  .Gcont .homenavs {
    position: relative;
    top: 60px;
    margin-bottom: 120px;
  }
  .Gintro {
    /* width: 80%; */
    left: -50px;
    top: -60px;
    position: relative;
  }
  .discN {
    width: 160px;
    height: 167px;
    position: relative;
    top: 17px;
    left: 20%;
    margin-top: 0px;
  }
  .Gcont .homenavs .btn2 img {
    height: 50px;
    position: relative;
    top: 65px;
    left: 1rem;
    width: 80%;
  }
  .Gcont .homebtn{
    width: 57px;
    position: relative;
    height: 73px;
    top: 8rem;
    left: 2rem;
  }
  .Gintro .p {
    font-size: 15px;
    width: 85%;
    word-spacing: 12px;
    
  }
}